<template>
  <SettingsOverlayStyled>
    <TabbedPanel :tabs="tabs" :title="$tc('setting', 2) | capitalize" @activateTab="activateTab">
      <template v-slot>
        <transition name="fade">
          <LanguageSelector v-if="activeTabID === 'language'" />
          <DateSettings v-if="activeTabID === 'date'" />
          <TimeSettings v-if="activeTabID === 'time'" />
          <UnitsSettings v-if="activeTabID === 'unit'" />
        </transition>
      </template>
    </TabbedPanel>
  </SettingsOverlayStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { TabbedPanel, LanguageSelector, DateSettings, TimeSettings, UnitsSettings } from '@common/components'

const SettingsOverlayStyled = styled('div')`
  .fade-leave-active {
    position: absolute;
  }
`

export default {
  components: {
    SettingsOverlayStyled,
    TabbedPanel,
    LanguageSelector,
    DateSettings,
    TimeSettings,
    UnitsSettings,
  },
  data() {
    return {
      activeTabID: 'language',
    }
  },
  computed: {
    tabs() {
      const tabs = [
        {
          id: 'language',
          name: this.$tc('language', 1),
        },
        {
          id: 'unit',
          name: this.$tc('unit', 2),
        },
        {
          id: 'date',
          name: this.$tc('date', 1),
        },
        // {
        //   id: 'time',
        //   name: this.$tc('time', 1),
        // },
      ]
      return tabs.map(tab => {
        return {
          ...tab,
          isActive: this.activeTabID === tab.id,
        }
      })
    },
  },
  methods: {
    activateTab(tabID) {
      this.activeTabID = tabID
    },
  },
}
</script>
