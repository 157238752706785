var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SettingsOverlayStyled', [_c('TabbedPanel', {
    attrs: {
      "tabs": _vm.tabs,
      "title": _vm._f("capitalize")(_vm.$tc('setting', 2))
    },
    on: {
      "activateTab": _vm.activateTab
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn() {
        return [_c('transition', {
          attrs: {
            "name": "fade"
          }
        }, [_vm.activeTabID === 'language' ? _c('LanguageSelector') : _vm._e(), _vm.activeTabID === 'date' ? _c('DateSettings') : _vm._e(), _vm.activeTabID === 'time' ? _c('TimeSettings') : _vm._e(), _vm.activeTabID === 'unit' ? _c('UnitsSettings') : _vm._e()], 1)];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }